:root {
  --persianBlue: #3762FC;
  --persianBlue60: #87A1FD;
  --persianBlue20: #D7E0FE;
  --black: #000000;
  --grey1: #2A323C;
  --grey2: #697D96;
  --grey3: #93ABC1;
  --grey4: #C2D2E7;
  --grey6: #E5EEF9;
  --grey7: #F3F7FB;
  --grey8: #92A4BB;
  --white: #FFFFFF;
  --negative1: #D43A3A;
  --negative2: #F6D8D8;
  --negative3: #FBEBEB;
  --warning1: #D89E08;
  --warning2: #F7ECCE;
  --warning3: #FBF5E6;
  --orange: #F06A12;
  --permutationBoard: #C9D1DB;
  --wireframeLine: #734C99;
  --positive1: #22B967;
  --positive2: #D3F1E1;
  --positive3: #E9F8F0;
  --mayaBlue: #69CBE4;
  --mayaBlue60: #9BE0EF;
  --mayaBlue20: #DEF5FA;
  --neutralGrey: #D4E0F0;
  --neutralGrey3: #93A8C1;
  --tertiaryYellow: #F0AB44;
  --tertiaryMagenta: #F15E79;
  --secondary_MayaBlue: #59CBE4;
  --red: #FF0000;
}


@font-face {
  font-family: "Bold";
  src: url(./assets/fonts/IBMPlexSansThai-Bold.ttf);
}

@font-face {
  font-family: "ExtraLight";
  src: url(./assets/fonts/IBMPlexSansThai-ExtraLight.ttf);
}

@font-face {
  font-family: "Light";
  src: url(./assets/fonts/IBMPlexSansThai-Light.ttf);
}

@font-face {
  font-family: "Medium";
  src: url(./assets/fonts/IBMPlexSansThai-Medium.ttf);
}

@font-face {
  font-family: "Regular";
  src: url(./assets/fonts/IBMPlexSansThai-Regular.ttf);
}

@font-face {
  font-family: "SemiBold";
  src: url(./assets/fonts/IBMPlexSansThai-SemiBold.ttf);
}

@font-face {
  font-family: "ThaiText";
  src: url(./assets/fonts/IBMPlexSansThai-Text.ttf);
}

@font-face {
  font-family: "Thin";
  src: url(./assets/fonts/IBMPlexSansThai-Thin.ttf);
}

a {
  text-decoration: none !important;
}

p {
  margin: 16px 0px 16px 0px !important;
}


.details-text-date {

  font-size: 14px;
  font-family: Regular;
  color: var(--grey1);
}

.date-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.btn-manu {

  display: initial;
  padding: 4px 8px;
  font-size: 16px;
  font-family: bold;
  border-radius: 50px;
  color: var(--persianBlue);
  background-color: var(--persianBlue20);
}

.btn-manu-active {
  margin-top: 24px;
  display: initial;
  padding: 4px 8px;
  font-size: 16px;
  font-family: bold;
  border-radius: 50px;
  color: var(--white);
  background-color: var(--persianBlue);
}

.box-shadow {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
}

.box-button {

  bottom: 0;
  display: flex;
  width: 100%;
  height: 88px;
  position: fixed;
  background-color: var(--white);
  z-index: 1000;
  padding-right: 16px;
  box-shadow: 2px 6px 20px 20px rgb(255 255 255 / 90%);
}

.btn-persianBlue {
  width: calc(100% - 32px);
  height: 48px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: bold;
  color: var(--white);
  background-color: var(--persianBlue);
}

.btn-persianGrey {
  width: calc(100% - 32px);
  height: 48px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: bold;
  color: var(--white);
  background-color: var(--grey3);
}

.btn-persianWhite {
  width: calc(100% - 32px);
  height: 48px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  font-family: bold;
  color: var(--persianBlue);
  background-color: var(--white);
}

.sliding-bar {
  position: fixed;
  top: 48px;
  height: 48px;
  left: 0;
  width: calc(100% - 32px);
  background-color: var(--white);
  padding: 15px;
  z-index: 100;
  transition: top 0.3s;
}

.sliding-bar.visible {
  top: 0;
}

.sliding-bar2 {
  position: fixed;
  left: 0;
  width: 100%;
  padding: 12px 16px 0px 0px;
  z-index: 100;
  top: -50px;
  transition: top 0.3s, background-color 0.3s;
}

.sliding-bar2.visible {
  background-color: var(--white);
  top: 0;
}


.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none
}

/* ให้ modal เลื่อนจาก ข้างล่างมา */
.run-time .modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -16px)
}



@media (prefers-reduced-motion:reduce) {
  .modal.fade .modal-dialog {
    transition: none
  }
}

.modal.show .modal-dialog {
  transform: none
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02)
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2)
}


.run-time .modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0 !important;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: 16px 16px 0 0;
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.react-datepicker__input-container>input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.error-from {
  color: red;
  font-size: 14px;
  font-family: Regular;
}

.ml-3 {
  margin-left: 24px;
}

.label-end {
  float: right;
  font-size: 16px;
  font-family: Regular;
  color: var(--grey3);
}

.pointer {
  cursor: pointer;
}