.justify-between {
    display: flex;
    justify-content: space-between;
}

.box-activity {
    margin-top: 100px;
    padding: 0px 16px 16px 16px;
    background-color: var(--white);

}


.nav-activity {
    width: 100%;
    height: 31px;
    display: flex;
    justify-content: space-between;
}

.text-activity {
    font-size: 24px;
    font-family: Bold;
    color: var(--grey1);
}

.history {
    margin-top: 4px;
    width: 24px;
    height: 24px;
}

.button-head {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-family: bold;
}

.head-box {
    width: 50%;
    height: 39px;
}



.head-text {
    color: var(--neutralGrey3);
    text-align: center;
}

.head-active {
    color: var(--persianBlue);
    text-align: center;
}

.active-hr-button {
    width: 100%;
    height: 2px;
    background-color: var(--persianBlue);
    margin: 0 !important;
}

.hr-button {
    width: 100%;
    height: 2px;
    background-color: var(--neutralGrey3);
    margin-top: 0;
}

.activity-box-user {
    position: relative;
    width: 100%;
    height: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* Adjust the values as needed */
    margin-bottom: 24px;
    border-radius: 16px;
    padding-bottom: 16px;
}

.activity-image {
    position: relative;
    margin-top: 24px;
    width: 100%;
    height: 193px;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    background-color: var(--positive1);
    z-index: 1;

}

.img-activity {
    position: relative;
    width: 100%;
    height: 193px;
    z-index: 0;
}

.opacity-tick {
    opacity: 0.5;
}




.details-text {
    font-size: 16px;
    font-family: bold;
    color: var(--black);
    margin: 16px 16px 0px 16px !important;
}

.details-text-date {
    font-size: 14px;
    font-family: Regular;
    color: var(--grey1);
    margin: 0px 16px 0px 16px !important;
}

.date-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.fixed-top {
    position: sticky;
    top: 43px;
    /* or any other value depending on your design */
    z-index: 1000;
    background-color: var(--white);
    padding: 0px 16px 16px 16px;
}

.navbar-fixed-top {

    width: 100%;
    height: 48px;
    background-color: var(--white);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    /* Adjust the z-index as needed */
}

.success-text {
    margin: 0px 16px;
    font-size: 14px;
    font-family: Regular;
    color: var(--grey2);
}


.progress-activity {
    margin-top: -8px;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
    height: 8px;
    border-radius: 50px;
    background-color: var(--grey6);
}

.progress-bar {
    height: 100%;
    background-color: var(--neutralGrey3);
    border-radius: 50px;
}

.progress-bar-active {
    height: 100%;
    background-color: var(--persianBlue);
    border-radius: 50px;
}

.view-scores {
    margin-top: 21px;
    font-size: 16px;
    font-family: bold;
    text-align: center;
    color: var(--persianBlue60);
}

.scores-text {
    font-family: bold;
    color: var(--persianBlue);
}

.img-tick3x {
    position: absolute;
    top: 77px;
    left: 50%;
    transform: translate(-50%);
    width: 40px;
    height: 40px;
    z-index: 4;
}

.emptyState-icon {
    position: absolute;
    width: 120px;
    height: 84px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.no-activity {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--grey2);
    font-size: 16px;
    font-family: bold;

}

.checked-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.box-checked {
    width: calc(100% - 32px);
    position: absolute;
    padding-left: 16px;
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    background-color: var(--white);
    height: 40px;
    z-index: 100;
    bottom: 0;
    margin-bottom: 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}