.box-head-img {
    position: relative;
    width: 100%;
    height: 212px;
}

.img-Ads {
    width: 100%;
    height: 100%;
    z-index: 0;
}

.close-button {
    position: absolute;
    width: 32px;
    height: 32px;
    margin-top: 60px;
    margin-left: 16px;

}

.box-content {
    padding: 0px 16px 150px 16px;

}

.text-head {
    font-size: 20px;
    font-family: bold;
    color: var(--black);
}

.mt--16 {

    margin-bottom: 24px !important;
}

.mr-9 {
    margin-right: 9px;
}

.message-content {
    position: relative;
    font-size: 16px;
    font-family: Regular;
    color: var(--grey1);
    margin-bottom: 150px;
}

.box-reward {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
}

.mt-box-reward {
    margin-top: 16px !important;
}

.mb-box-reward {
    margin-top: 16px;
    padding-bottom: 64px;
}

.reward-img {
    width: 80px;
    height: 100%;
    margin-right: 16px;
}

.reward-img img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.reward-one {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.reward-too {

    display: flex;
    justify-content: space-between;
}

.reward-tee {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.reward-too-user {
    margin-top: 24px;
}

.mb-16 {
    margin-bottom: 16px;
    margin-top: 16px;
}

.reward-1 {
    font-size: 16px;
    font-family: bold;
    color: var(--grey1);

}

.reward-user {
    margin-left: 0px;
    font-size: 20px;
    font-family: bold;
    color: var(--grey1);

}

.reward-2 {
    font-size: 12px;
    font-family: regular;
    color: var(--neutralGrey3);
    position: absolute;
    right: 16px;
}

.reward-scoreboard {
    padding: 0;
    font-size: 16px;
    font-family: regular;
    color: var(--persianBlue);
}

.reward-detail {
    font-size: 16px;
    font-family: regular;
    color: var(--grey2);

}

.reward-detail-2 {
    margin-top: 0px;
    font-size: 16px;
    font-family: regular;
    color: var(--grey2);
}

.img-reward-succeed {
    width: 100%;
    height: 343px;
    border-radius: 8px;
}

.img-right {
    margin-bottom: 0px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.tec {
    position: relative;
    font-size: 16px;
    font-family: regular;
    color: var(--black);
}

.box-reward-succeed {
    margin-bottom: 24px;
}

.score-user {
    font-size: 20px;
    font-family: bold;
    color: var(--black);
}

.score {
    font-size: 16px;
    font-family: Regular;
    color: var(--persianBlue);
}

.progress-activity {
    margin-top: -8px;
    position: relative;
    height: 16px;
    border-radius: 50px;
    background-color: var(--grey6);
}

.progress-bar {
    height: 100%;
    background-color: var(--neutralGrey3);
    border-radius: 50px;
}

.progress-bar-active {
    height: 100%;
    background-color: var(--persianBlue);
    border-radius: 50px;
}

.scores-text {
    font-family: bold;
    font-size: 14px;
    color: var(--persianBlue);
}

.date-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.tex-pace {
    font-size: 12px;
    color: var(--grey2);
    font-family: Regular;
}

.mt-bar {
    margin-top: 8px;
}

.mt--bar {
    margin-top: -16px;
}

.score-board {
    color: var(--persianBlue);
    font-size: 16px;
    font-family: Regular;
}

.box-frame13754 {
    width: calc(100% - 32px);
    height: 56px;
    border-radius: 100px;
    background-color: var(--grey6);
    bottom: 40px;
    position: absolute;
    z-index: 0;
}

.start-exercising {
    text-align: center;
    z-index: 1;
    color: var(--grey2);
    font-size: 16px;
    font-family: bold;
}

.frame13 {
    position: absolute;
    z-index: 2;
    width: 72px;
    height: 40px;
    margin: 8px 12px;
}

.frame13 {
    transition: left 0s ease;

    /* Adjust the duration and timing function as needed */

    /* ลำดับเริ่มต้น */
}

.left {
    order: 1;
    /* ลำดับเมื่อลากไปทางซ้าย */
}

.right {
    order: 0;
    /* ลำดับเมื่อลากไปทางขวา */
}

.box-time {

    padding: 48px 16px;
}

.time {
    text-align: center;
    font-size: 16px;
    font-family: Regular;
    color: var(--grey2);
}

.count-time {
    text-align: center;
    font-size: 64px;
    font-family: bold;
    color: var(--black);
}

.justify-around {
    display: flex;
    justify-content: space-around;
}

.text-walk {
    text-align: center;
    font-size: 16px;
    font-family: Regular;
    color: var(--grey2);
}

.count-walk {
    font-size: 48px;
    font-family: bold;
    color: var(--black);

}

.run-1 {
    display: flex;
    margin-top: -100px;
    width: 100%;
    height: 599px;
    background-image: url('../image/icon/run.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}



.box-run {
    padding: 99px 16px 32px 16px;
}


.box-start-run {
    bottom: 42px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.img-stop {
    width: 80px;
    height: 80px;
}

.btn-play {
    width: 80px;
    background-color: var(--white);
    height: 80px;
    color: var(--secondary_MayaBlue);
    border-radius: 50px;
    border: 2px solid var(--secondary_MayaBlue);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    font-size: 20px;
    font-family: bold;
}

.btn-finish {
    width: 80px;
    background-color: var(--secondary_MayaBlue);
    height: 80px;
    color: var(--white);
    border-radius: 50px;
    border: 2px solid var(--secondary_MayaBlue);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: bold;
}

.text-abandon {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    font-size: 16px;
    font-family: bold;
    color: var(--grey2);
    position: absolute;
}

.modal-bottom {
    bottom: 0 !important;
    position: absolute;
    width: 100%;
    margin-bottom: -16px;
}

.contextual {
    padding-top: 34px !important;
    display: flex;
    justify-content: center;
}

.img-contextual {
    width: 120px;
    height: 120px;

}



.confirm-abandon {
    text-align: center;
    margin-top: 24px !important;
    font-size: 20px;
    font-family: bold;
    color: var(--grey1);
}

.btn-box-abandon {
    margin-top: 32px !important;
    margin-bottom: 40px !important;
    margin-left: 16px;
    display: flex;
    flex-direction: row;
}

.btn-play-Abandon {
    width: 155px;
    background-color: var(--white);
    height: 48px;
    color: var(--persianBlue);
    border-radius: 50px;
    border: 2px solid var(--persianBlue);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    font-size: 20px;
    font-family: bold;
}

.btn-finish-Abandon {
    width: 155px;
    background-color: var(--persianBlue);
    height: 48px;
    color: var(--white);
    border-radius: 50px;
    border: 2px solid var(--persianBlue);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: bold;
}