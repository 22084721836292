.justify-between {
    display: flex;
    justify-content: space-between;
}


.box-scoreboard {
    margin-top: 16px;
    padding: 0px 16px 64px 16px;
}

.box-scoreboard2 {
    margin-top: 64px;
    padding: 0px 16px 64px 16px;
}

.scoreboard-list {
    margin-top: 120px !important;
    font-size: 24px;
    font-family: bold;
    color: var(--grey1);
}

.box-list {
    margin: 0px !important;
}

.list-head {
    width: 100%;
    display: flex;
    justify-content: end;
    font-size: 14px;
    color: var(--grey2);
    font-family: Regular;
}



.list-user {
    margin-top: -16px;
    display: flex;
    /*     justify-content: space-between; */
}

.list-user .list-id {
    width: 32px;
    padding-right: 8px;
    height: auto;
}

.list-name {
    font-size: 16px;
    font-weight: bold;
    color: var(--grey1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.list-id {
    font-size: 14px;
    font-family: Regular;
    color: var(--grey1);
}

.list-score {

    font-size: 16px;
    font-family: Regular;
    color: var(--grey1);
}

.box-list-score {
    width: 64px;
    margin-left: 16px;
}

.list-row {
    display: flex;
    flex-direction: row;
}

.box-list-name {
    margin-top: 13px;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background-color: var(--persianBlue);
    color: var(--white);
    font-size: 13px;
    font-family: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.score-user {
    margin: -8px -8px 12px -8px !important;
    background-color: var(--mayaBlue20);
    border-radius: 8px;
    padding: 0px 8px;

}

.box-list-icon {
    width: 32px;
    /*    height: 32px; */
}

.box-name-list {
    margin-left: 16px;
    width: 180px;
}

.box-head-list {
    width: 150px;
}

.align-right {
    display: flex;
    text-align: right !important;
    margin-right: 10px;
    /* ปรับตำแหน่งตามความต้องการ */
}

.box-coin {
    width: "100%";
    height: 313px;
    background: url('../../assets/image/icon/BACKGROUND.png');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center !important;
    align-items: flex-end !important;
}



.stab-gold {
    width: 88px;
    height: 150px;
    background: linear-gradient(hsla(43, 93%, 44%, 0.800), hsla(43, 93%, 44%, 0));
}

.gold-coin {
    width: 72px;
    height: 72px;
    margin-left: 8px;
}

.gold-name-0 {
    position: absolute;
    font-size: 24px;
    margin-top: 21px;
    margin-left: -42px;
    z-index: 10;
    font-family: bold;
    color: var(--white);
}

.stab-silver {

    width: 88px;
    height: 117px;
    background: linear-gradient(hsla(213, 27%, 67%, 0.800), hsla(213, 27%, 67%, 0));
}

.silver-coin {
    margin-bottom: -16px;
    margin-top: 35px;
    width: 86px;
    height: 86px;
    margin-left: 4px;
    z-index: 0;
}

.silver-name-0 {
    position: absolute;
    font-size: 24px;
    margin-top: -50px;
    margin-left: 41px;
    z-index: 10;
    font-family: bold;
    color: var(--white);
}

.stab-copper {

    width: 88px;
    height: 96px;
    background: linear-gradient(hsla(18, 58%, 69%, 0.800), hsla(18, 58%, 69%, 0));
}

.box-coin-name {
    position: relative !important;

}

.copper-name-0 {
    position: absolute;
    font-size: 24px;
    margin-top: 78px;
    margin-left: -34px;
    z-index: 10;
    font-family: bold;
    color: var(--white);
}

.copper-coin {
    position: relative;
    margin-top: 65px;
    width: 56px;
    height: 56px;
    margin-left: 16px;
    z-index: 0;
}

.name-coin {
    margin-top: 0px !important;
    margin-bottom: 8px !important;
    text-align: center;
    font-family: bold;
    font-size: 16px;
    color: black;
}

.coin-number {
    margin-top: 8px;
    font-family: bold;
    font-size: 16px;
    text-align: center;
    color: var(--white);

}

.icon-step {
    width: 16px;
    height: 16px;
    /*     margin-right: 4px; */
}

.number-step {
    margin-left: 8px !important;
    font-family: Regular;
    color: var(--grey1);
    font-size: 14px;
}

.number-step2 {
    margin-top: -8px !important;
    margin-left: 8px !important;
    font-family: Regular;
    color: var(--grey1);
    font-size: 14px;
}