body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "IBM Plex Sans Thai", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  ul ul {
    list-style-type: disc;
  }
  .cookie-rejection {
    padding: 2rem;
    background-color: #f3f7fb;
    color: #d8000c;
    margin: 2rem;
    border: 1px solid #d8000c;
  }
  
  .middle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-home2-1 {
    width: 80%;
  }
  .text-section {
    display: flex;
    align-items: start;
    flex-direction: column;
  }
  .image-section {
    flex: 1;
    width: 80%;
  }
  
  .image {
    max-width: 100%;
    height: auto;
  }
  
  .navbar {
    display: flex;
    align-items: start;
    justify-content: start;
  }
  
  .logo {
    height: 48px;
    padding: 10px;
    margin-left: 1.8%;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .home1 {
    background: url("./img/description_BG.webp");
    width: 100%;
  }
  .content-home1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .wellly-home1 {
    width: 360px;
    height: 144px;
    margin-bottom: 24px;
  }
  .text-home1 {
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
  }
  .image-home1 {
    width: 75%;
    height: 75%;
  }
  .div-text-home1 {
    margin-left: 10vw;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .font-size-24 {
    font-size: 24px;
  }
  .font-size-20 {
    font-size: 20px;
  }
  .font-size-16 {
    font-size: 16px;
  }
  .color-1149E6 {
    color: #1149e6;
  }
  .content-detail {
    display: grid;
    grid-template-columns: 0.45fr 0.55fr;
    justify-content: center;
    align-content: center;
    margin-bottom: 10px;
  }
  .margin-top-content {
    margin-top: 5%;
  }
  .margin-bottom-content {
    margin-bottom: 10%;
  }
  .margin-bottom-right {
    margin-right: 1rem;
  }
  .image-margin-bottom-right {
    margin-right: 5px;
  }
  .padding-left-first-div {
    padding-left: 10vw;
  }
  .header-home2-1,
  .header-home2-2,
  .header-home2-3 {
    font-weight: 600;
    font-size: 32px;
  }
  .header-home2-1 {
    width: 71.5%;
  }
  .header-home2-2,
  .header-home2-3 {
    width: 70%;
  }
  .ul-home2-1 {
    width: 80%;
  }
  .text-home2-2 {
    width: 75%;
    height: 75%;
  }
  .detail-home2-3 {
    display: grid;
    grid-template-columns: 1fr 4fr;
    background-color: #f3f7fb;
    margin-bottom: 20px;
  }
  .text-home2-3 {
    width: 75%;
    padding-left: 8%;
    font-size: 20px;
  }
  .text-detail-home2-3 {
    font-weight: 700;
    width: 85%;
  }
  .image-margin-top {
    margin-top: 2em;
  }
  .image-home2-3 {
    width: 75%;
    padding: 20px 25%;
  }
  
  .home3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #def5fa;
  }
  .header-home3 {
    font-size: 40px;
    font-weight: 600;
  }
  
  .header-home3-1,
  .header-home3-2 {
    font-weight: 700;
    font-size: 32px;
  }
  .header-home3-1 {
    width: 75%;
  }
  .header-home3-2 {
    width: 90%;
  }
  .text-home3-1 {
    width: 75%;
  }
  .image-home3-2 {
    width: 75%;
  }
  .text-home3-2 {
    width: 87%;
  }
  
  .home4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .header-home4 {
    font-size: 40px;
    font-weight: 600;
  }
  .image-home4 {
    width: 80%;
    margin-bottom: 10%;
  }
  
  @media (max-width: 768px) {
    .font-size-24 {
      font-size: 20px;
    }
    .font-size-20 {
      font-size: 16px;
    }
    .wellly-home1 {
      width: 180px;
      height: 72px;
      margin-bottom: 0;
    }
  }
  @media (max-width: 480px) {
    ul,
    ol {
      padding-inline-start: 24px;
    }
    .image-section {
      width: 90%;
    }
    .content-home1 {
      grid-template-columns: 1fr;
    }
    .div-text-home1 {
      margin-top: 60px;
    }
    .wellly-home1 {
      margin-bottom: 1rem;
    }
    .text-home1 {
      text-align: center;
    }
    .H3 {
      font-weight: 700;
    }
  
    .content-detail {
      display: flex;
      margin-left: 20px;
      flex-direction: column;
    }
    .convert-div {
      flex-direction: column-reverse;
    }
  
    .no-image {
      display: none;
    }
    .header-home2-1,
    .header-home2-2,
    .header-home2-3 {
      font-size: 24px;
    }
    .header-home2-1 {
      width: 85%;
    }
    .header-home2-2 {
      width: 75%;
    }
    .header-home2-3 {
      width: 100%;
    }
    .ul-home2-1 {
      width: 92%;
    }
    .text-home2-1 {
      width: 94%;
    }
    .padding-left-first-div {
      padding-left: 0;
    }
    .text-home2-2 {
      width: 93%;
    }
    .text-home2-3 {
      font-size: 16px;
      width: 92%;
    }
    .image-home2-3 {
      align-items: start;
    }
    .header-home3 {
      font-size: 36px;
      margin-top: 1em;
    }
    .text-home3-1 {
      width: 94%;
    }
    .header-home3-1 {
      margin: 0;
    }
    .header-home3-1,
    .header-home3-2 {
      font-size: 24px;
    }
    .header-home3-2 {
      width: 98%;
    }
    .text-home3-2 {
      width: 94%;
    }
    .image-home3-2 {
      width: 95%;
    }
  }  