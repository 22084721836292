.event-creator {
    padding: 9px 16px 0px 16px;
    font-size: 32px;
    font-family: bold;
    color: var(--black);
    text-align: center;
}

.forgot-password-text {
    /* padding: 9px 16px 0px 16px; */
    font-size: 16px;
    font-family: Regular;
    color: var(--black);
    text-align: center;
    line-height: 6px;
}

.from-login {
    padding: 71px 16px 168px 16px;
}

.button-login {
    margin-top: 32px;
    width: 100%;
    height: 65px;
    border-radius: 24px;
    border: 2px solid var(--persianBlue);
    background-color: var(--persianBlue);
    color: var(--white);
    font-size: 16px;
    font-family: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgot-password {
    margin-top: 32px !important;
    color: var(--persianBlue);
    font-size: 16px;
    font-family: bold;
    text-align: center;
}

.grid-container1 {
    display: grid;
    grid-template-columns: auto auto;
}

.grid-container2 {
    display: grid;
    grid-template-columns: auto auto auto;
}

.grid-item1 {
    background-color: rgba(255, 255, 255, 0.8);
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding-top: 10px;
    padding-left: 80px;
    font-size: 30px;
}

.grid-item2 {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 30px;
}

.grid-item3 {
    background-color: rgba(255, 255, 255, 0.8);

    font-size: 30px;
    text-align: right;
}

.grid-item4 {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 30px;
    text-align: center;
}

.Logo {
    height: 40px;
}

.content {
    color: black;
    font-family: Regular;
    font-size: 18px;
}

.forgot-password {
    font-family: Bold;
}

.login-submit {
    font-family: Bold;
}

.key {
    height: 120px;
}

.phone {
    height: 24px;
    margin: 25px;
}

.calling {
    font-family: bold;
    padding-left: 20px;
}

.calling_number {
    font-family: Regular;
    font-size: 16px;
    line-height: 1px;
    padding-left: 20px;
}

.detail-group {
    line-height: 12px;
    margin-top: 18px;
}