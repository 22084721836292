.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-criteria {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-family: Regular;
    color: var(--black);
}

.flex-row-criteria>div {
    margin-top: 24px;
    margin-right: 36px;
}

/* หรือถ้าไม่ต้องการให้ลงทุนทางขวาของ Flex Item สุดท้าย */
.flex-row-criteria>div:last-child {
    margin-right: 0;
}

.box-challenge {
    padding-top: 104px;
    width: 223px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #D9D9D9;
    overflow-y: auto;


}

.challenge {
    width: 100%;
    min-height: 100vh;
    background-color: var(--white);
    padding: 0px 120px;
    flex: 1;
    /* เพิ่ม flex เพื่อให้ .challenge ขยายขนาดตามขนาดของ .box-challenge */
}

.box-new-activity {
    margin-top: 16px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


}

.new-activity {
    color: var(--grey1);
    font-size: 24px;
    font-family: bold;
}

.hr {
    background-color: var(--black);
    width: 100%;
    height: 5px;
    border-radius: 5px;
}

.name-activity {
    margin-top: 40px !important;
    font-size: 18px;
    font-family: bold;
    color: var(--black);
}

.name-activity>span {
    color: var(--red);
}

.date-picker {
    position: relative;
}

.calendar {
    margin-top: -32px;
    position: absolute;
    display: flex;
    right: 16px;
    width: 24px;
    height: 24px;
}

.period {
    margin-top: 24px !important;
    font-size: 18px;
    font-family: bold;
    color: var(--black);
}

.period>span {
    color: var(--red);
}

.btn-next {
    width: auto;
    height: auto;
    padding: 12px 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 2px solid var(--grey4);
    background-color: var(--grey4);
    color: var(--grey3);
    font-size: 18px;
    font-family: bold;
}

.btn-next-active {
    width: auto;
    height: auto;
    padding: 12px 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 2px solid var(--persianBlue);
    background-color: var(--persianBlue);
    color: var(--white);
    font-size: 18px;
    font-family: bold;
}

.btn-cancel {
    width: auto;
    height: auto;
    padding: 12px 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 2px solid var(--persianBlue);
    background-color: var(--white);
    color: var(--persianBlue);
    font-size: 18px;
    font-family: bold;

}



.flex-row-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* จัดตำแหน่งไปทางขวา */
}

.flex-row-btn>button {

    margin-top: 48px;
    margin-right: 19px;
    margin-bottom: 64px;

}

/* หรือถ้าไม่ต้องการให้ลงทุนทางขวาของ Flex Item สุดท้าย */
.flex-row-btn>button:last-child {
    margin-right: 0;
}

.flex-row-btn-ml {
    display: flex;
    flex-direction: row;
    display: flex;
    justify-content: start;
    /* จัดตำแหน่งไปทางขวา */
}

.flex-row-btn-ml>button {

    margin-top: 48px;
    margin-right: 19px;
    margin-bottom: 64px;

}

/* หรือถ้าไม่ต้องการให้ลงทุนทางขวาของ Flex Item สุดท้าย */
.flex-row-btn-ml>button:last-child {
    margin-right: 0;
}

.flex-row-radios {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-family: Regular;
    color: var(--black);
}

.flex-row-radios>div {
    margin-right: 8%;
}

/* หรือถ้าไม่ต้องการให้ลงทุนทางขวาของ Flex Item สุดท้าย */
.flex-row-radios>div:last-child {
    margin-right: 0;
}

.box-person {
    position: relative;
}

.person {
    margin-top: -32px;
    position: absolute;
    right: 16px;
}

.span-red {
    color: red;
}

.ml {
    margin-left: 64px;
}

.box-rewards {
    margin-top: 40px !important;




}

.overflow-x {
    overflow-x: auto;
}

.box-rewards>div {
    margin-right: 16px;
}

/* หรือถ้าไม่ต้องการให้ลงทุนทางขวาของ Flex Item สุดท้าย */
.box-rewards>div:last-child {
    margin-right: 0;
}

.rewards-active {
    width: 111px;
    height: 47px;
    background-color: var(--persianBlue);
    color: var(--white);
    font-size: 18px;
    font-family: Regular;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.rewards {
    width: 111px;
    height: 47px;
    background-color: var(--white);
    color: var(--persianBlue);
    font-size: 18px;
    font-family: Regular;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}

.add-rewards {
    width: 120px !important;
    height: 48px;
    background-color: var(--persianBlue);
    color: var(--white);
    font-size: 18px;
    font-family: Regular;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    cursor: pointer;
    border: 2px solid var(--persianBlue);

}

.group7728 {
    width: 240px;
    height: 240px;
    cursor: pointer;
}

.nav-logo {
    width: 100%;
    height: 80px;
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.img-Logo_web {
    margin-left: 24px;
    margin-top: 21px;
    width: 92px;
    height: 40px;
}

.img-User {
    margin-right: 24px;
    margin-top: 16px;
    width: 40px;
    height: 40px;
}

.user-name {
    margin-top: 24px;
    margin-right: 8px;
    font-size: 16px;
    font-family: bold;
    color: var(--grey1);
}

.img-col {
    width: 16px;
    height: 16px;
}

.span-col {
    margin-top: 8px;
    width: 110px;
    height: 2px;
    background-color: var(--neutralGrey);
}

.flex-row-date {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
}

.box-criteria {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.flex-end-criteria {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.button-0 {

    margin-top: 20%;
    margin-bottom: 64px;
}

.mr-16 {
    margin-right: 16px !important;
}

.box-rewards {
    padding-top: 40px;
    display: flex;
    justify-content: center;

}

.box-upload {
    width: 784px;
    height: 324px;
    border-radius: 16px;
    border: 1px solid var(--grey4);
    padding: 24px;
    position: relative;
    margin-bottom: 24px;
}

.rewards-number {
    margin-bottom: 15px !important;
    font-size: 16px;
    font-family: bold;
    color: var(--grey1);
}

.quantity {
    position: relative;
    margin-top: 24px;
    width: 180px;
}

.quantity span {
    position: absolute;
    right: 16px;
    margin-top: -30px;
}

.box-add-rewards {
    padding-top: 24px;
    display: flex;
    justify-content: center;
}

.icon-delete {
    width: 24px;
    height: 24px;
}

.upload-head {
    margin-top: 64px;
    width: 100%;
    height: 100%;
    max-width: 588px;
    max-height: 331px;
}