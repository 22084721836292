.navbar {

    width: 100%;
    height: 48px;
    background-color: var(--white);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    /* Adjust the z-index as needed */
}

.chevron {
    width: 24px;
    height: auto;
    margin: 12px 16px;
}

.chevron-scor {
    width: 24px;
    height: auto;
    margin: 65px 16px 0px 16px;
}