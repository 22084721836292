.footer-contact {
  background-color: #3762fc;
  height: 391px;
}
.contact-detail {
  flex-direction: column;
  color: #fff;
}
.contact-us-footer {
  display: flex;
  flex-direction: row;
  border: none;
}
.bbpf {
  font-size: 24px;
  font-weight: 700;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}
.bbpf-address {
  font-size: 20px;
  margin-block-start: 0;
  margin-block-end: 24px;
}
.text-contact-us {
  font-size: 24px;
  font-weight: 600;
  margin-block-start: 0;
  margin-block-end: 0;
}

.text-contact,
.text-policy {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding-left: 0;
  padding-inline: 0;
  font-weight: 600;
}
.text-contact {
  font-size: 20px;
}
.policy-banding {
  flex-direction: row;
  height: 88px;
  background-color: #4a4a4a;
  color: #fff;
  font-size: 12px;
}
.image-horizon {
  margin: 0 1rem;
}
.logo-wellly {
  width: 131px;
  height: 53px;
}
.icon-contact {
  width: 24px;
  height: 24px;
}
.text-cookie-policy{
  display: flex;
  flex-direction: row;
}
.license-policy{
  font-weight: 600;
}

@media (max-width: 480px) {
  .footer-contact {
    height: 300px;
    width: 100%;
  }
  .logog-wellly {
    width: 87px;
    height: 36px;
  }
  .icon-contact {
    width: 20.75px;
    height: 20.75px;
  }
  .bbpf {
    font-size: 14px;
    margin-block-start: 10px;
    margin-block-end: 10px;
  }
  .bbpf-address {
    font-size: 12px;
    width: 70%;
    text-align: center;
    margin-block-end: 1rem;
  }
  .contact-us-footer {
    flex-direction: column;
  }
  .text-contact-us {
    font-size: 14px;
  }
  .text-contact{
    font-size: 12px;
  }

  .license-policy{
    display: flex;
  }
  .policy-banding{
    flex-direction: column;
    align-items: start;
    padding: 0 1rem;
    font-size: 10px;
  }
  .text-policy{
    font-size: 10px;
    margin-block-end: 0;
  }
}
